<template>
  <v-container fluid>
    <v-row>
      <v-col md="6" cols="12">
        <div class="conOfHeader">
          <div class="headerOfPage">Departments</div>
        </div>
      </v-col>
      <v-col md="6" cols="12">
        <div class="conOfHeader text-right">
          <div class="btnAdd">
            <v-btn class="btnAddPrimary" @click="dialogProject = true">
              <v-icon>mdi-plus</v-icon>
              Create New
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="listData && listData.length > 0">
      <v-col cols="12">
        <section class="conOfProjects">
          <div class="conOfTable">
            <v-data-table
              :headers="headers"
              :items="listData"
              :items-per-page="10"
              hide-default-footer
            >
              <template v-slot:[`item.permissions`]="{ item }">
                <v-col md="4">
                  <div
                    class="d-flex"
                    v-for="permission in item.permissions"
                    :key="permission.name"
                  >
                    {{ permission.name }}
                  </div>
                </v-col>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom :left="true" :offset-y="true">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="editItem(item)">
                      <v-list-item-title class="statusTxt">
                        Edit
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                <div>{{ item.created_at | formatDate }}</div>
              </template>
              <template v-slot:[`item.name_en`]="{ item }">
                <router-link
                  :to="{
                    name: 'settingDepartmentsSub',
                    params: { id: item.id },
                  }"
                >
                  {{ item.name_en }}
                </router-link>
              </template>
            </v-data-table>
          </div>
        </section>
        <div
          class="conOfPagination pr-5 pt-5"
          v-if="pagination.total > pagination.per_page"
        >
          <v-pagination
            :total-visible="7"
            v-model="pagination.current_page"
            :length="pagination.last_page"
            @input="goToPage(pagination.current_page)"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <v-row v-else>
      <v-col cols="12" class="mt-7">
        <EmptyState />
      </v-col>
    </v-row>
    <v-snackbar
      v-model="successSnackbar"
      color="success"
      shaped
      absolute
      top
      right
      :timeout="timeout"
    >
      {{ successMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      color="red"
      shaped
      top
      right
      :timeout="timeout"
    >
      {{ errorMessage }}
    </v-snackbar>
    <v-dialog scrollable v-model="dialogProject" max-width="700" persistent>
      <v-card class="conOfPopup">
        <v-card-title>
          <span class="text-h5 callbacks modalTitle"> {{ formTitle }}</span>
        </v-card-title>

        <v-card-text class="conFormSubmit scrollbar">
          <v-container>
            <v-row>
              <v-col cols="12">
                <label class="eachLabel">Department Name</label>
                <v-text-field
                  class="eachInput"
                  placeholder="Department Name"
                  outlined
                  solo
                  hide-details="auto"
                  v-model="formItem.name_en"
                  required
                  :error-messages="name_enErrors"
                  @input="$v.formItem.name_en.$touch()"
                  @blur="$v.formItem.name_en.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="arStyle">
                <label class="eachLabel">القسم</label>
                <v-text-field
                  class="eachInput"
                  placeholder="القسم"
                  outlined
                  solo
                  hide-details="auto"
                  v-model="formItem.name_ar"
                  required
                  :error-messages="name_arErrors"
                  @input="$v.formItem.name_ar.$touch()"
                  @blur="$v.formItem.name_ar.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label class="eachLabel">Description</label>
                <v-text-field
                  class="eachInput"
                  placeholder="Description"
                  outlined
                  solo
                  hide-details="auto"
                  v-model="formItem.description"
                  required
                  :error-messages="descriptionErrors"
                  @input="$v.formItem.description.$touch()"
                  @blur="$v.formItem.description.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label class="eachLabel">Permission Name</label>
                <v-row>
                  <v-col
                    v-for="(item, index) in permissions"
                    :key="index"
                    cols="4"
                  >
                    <div class="d-flex align-center">
                      <v-checkbox
                        v-model="formItem.permission_ids"
                        :value="item.id"
                        multiple
                      ></v-checkbox>
                      {{ item.name }}
                    </div>
                  </v-col>
                </v-row>
                <v-pagination
                  :total-visible="7"
                  v-model="perPagination.current_page"
                  :length="perPagination.last_page"
                  @input="goToPerPage(perPagination.current_page)"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="closeProjectModal">
            Cancel
          </v-btn>
          <v-btn
            class="btnPrimaryOrg"
            @click="saveItem"
            :disabled="isLoadingSave"
            :loading="isLoadingSave"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
import EmptyState from "@/modules/shared/components/emptystate";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
export default {
  mixins: [validationMixin],
  validations() {
    const validations = {
      formItem: {
        name_en: { required },
        name_ar: { required },
        description: { required },
      },
    };
    return validations;
  },
  data: () => ({
    successSnackbar: false,
    errorSnackbar: false,
    timeout: 3000,
    dialogProject: false,
    isLoadingSave: false,
    isLoading: false,
    editedIndex: -1,
    pagination: {
      current_page: 1,
    },
    perPagination: {
      current_page: 1,
    },
    formItem: {
      permission_ids: [],
      name_en: "",
      name_ar: "",
      description: "",
    },
    defaultItem: {},
    headers: [
      {
        text: "NAME",
        align: "start",
        value: "name_en",
        sortable: false,
      },
      { text: "DESCRIPTION", value: "description" },
      { text: "CREATE DATE", value: "created_at" },
      { text: "Permissons", value: "permissions" },
      { text: "", value: "actions", sortable: false, align: "right" },
    ],
    listData: [],
  }),
  computed: {
    ...mapGetters(["apiMessage"]),
    formTitle() {
      return this.editedIndex === -1 ? "Add New Department" : "Edit Department";
    },
    name_enErrors() {
      const errors = [];
      if (!this.$v.formItem.name_en.$dirty) return errors;
      !this.$v.formItem.name_en.required &&
        errors.push("Department Name Is Required.");
      return errors;
    },
    name_arErrors() {
      const errors = [];
      if (!this.$v.formItem.name_ar.$dirty) return errors;
      !this.$v.formItem.name_ar.required && errors.push("القسم مطلوبة");
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.formItem.description.$dirty) return errors;
      !this.$v.formItem.description.required &&
        errors.push("Description Is Required.");
      return errors;
    },
  },
  components: { EmptyState },
  methods: {
    editItem(item) {
      this.editedIndex = item.id;
      let editFormItem = {
        name_en: item.name_en,
        name_ar: item.name_ar,
        description: item.description,
        permission_ids: item.permissions.map((permission) => permission.id), // Initialize with all selected permission IDs
      };

      this.formItem = editFormItem;
      console.log("editFormItem", this.formItem);
      this.dialogProject = true;
    },
    itemEdited() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.isLoadingSave = true;
      console.log("this.formItem", this.formItem);
      apiServices
        .update(this.editedIndex, "departments", this.formItem)
        .then((res) => {
          if (res) {
            this.successMessage = res.message || "Successful";
            this.successSnackbar = true;

            this.pagination.current_page = 1;
            this.getListData(this.pagination.current_page);
            this.isLoadingSave = false;
            this.closeProjectModal();
          } else {
            this.isLoadingSave = false;
            let errors = Object.values(this.apiMessage)[0];
            errors.forEach((err) => {
              this.errorMessage = err;
            });
            this.errorSnackbar = true;
          }
        });
    },
    itemAddNew() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.isLoadingSave = true;

      apiServices.post("departments", this.formItem).then((res) => {
        if (res) {
          this.successMessage = res.message || "Successful";
          this.successSnackbar = true;

          this.pagination.current_page = 1;
          this.getListData(this.pagination.current_page);
          this.isLoadingSave = false;
          this.closeProjectModal();
        } else {
          this.isLoadingSave = false;
          let errors = Object.values(this.apiMessage)[0];
          errors.forEach((err) => {
            this.errorMessage = err;
            console.log("errorMessage", err);
          });
          this.errorSnackbar = true;
        }
      });
    },
    saveItem() {
      if (this.editedIndex > -1) {
        this.itemEdited();
      } else {
        this.itemAddNew();
      }
    },
    closeProjectModal() {
      this.editedIndex = -1;
      this.dialogProject = false;
      this.$nextTick(() => {
        this.$v.$reset();
        this.formItem = Object.assign({}, this.defaultItem);
      });
    },
    goToPerPage(page) {
      this.getPermissions(page);
      window.scrollTo(0, 0);
    },
    goToPage(page) {
      this.getListData(page);
      window.scrollTo(0, 0);
    },
    getListData(page) {
      this.listData = [];
      this.isLoading = true;
      apiServices.get(`departments?per_page=7&page=${page}`).then((res) => {
        if (res) {
          this.listData = res.data;
          this.pagination = res.meta;
          this.isLoading = false;
        } else this.isLoading = false;
      });
    },
    getPermissions(page) {
      this.permissions = [];
      this.isLoading = true;

      apiServices.get(`permissions?per_page=7&page=${page}`).then((res) => {
        if (res) {
          this.permissions = res.data;
          this.perPagination = res.meta;
          this.isLoading = false;
        } else this.isLoading = false;
      });
    },
  },

  created() {
    this.getListData(this.pagination.current_page);
    this.getPermissions();
  },
  watch: {
    dialogProject(val) {
      val || this.closeProjectModal();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_departments.scss";
</style>
